.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.HomeViewIconsWrapper {
  display: flex;
  flex-direction: row;
}

.HomeViewIcon {
  color: black !important;
}